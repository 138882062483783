import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import classNames from 'classnames';

import Layout from '../components/Layout';
import SectionFeature from '../components/sections/SectionFeature';
import SectionEarlyAccess from '../components/sections/SectionEarlyAccess';

import useResizeScreen from '../hooks/useResize';

import logoElastic from '../assets/images/icons/logo-full-svg/elastic.svg';
import logoOpenTelemetry from '../assets/images/icons/logo-full-svg/open-telemetry.svg';
import logoPrometheus from '../assets/images/icons/logo-full-svg/prometheus.svg';
import iconLowCode from '../assets/images/icons/general-svg/icon-low-code2.svg';

const ControlHubPage = () => {
  const { isMobile } = useResizeScreen();
  return (
    <Layout title="Jetic - Manage and Monitor Integration on Kubernetes">
      <div className="api-builder-page">
        <section className="first-section first-section--common">
          <div className="container pt-4">
            <div className="row">
              <div className="col-md-6">
                <div className="first-section__content">
                  <h1 className="header-700 mb-4">Control Hub</h1>
                  <h2 className="body-300 gray-500 mb-32">
                    Manage, optimize and monitor all your APIs and Integrations in one place
                  </h2>
                  <div className="btn-wrapper mb-3 pb-3">
                    <a
                      href="https://app.jetic.io/registration"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="c-btn c-btn--primary"
                    >
                      Sign up
                    </a>
                    <a href="https://" className="c-btn c-btn--white">
                      Documentation
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                {!isMobile ? (
                  <StaticImage
                    src="../assets/images/pages/api-builder/Illustration-main.png"
                    className="first-section__image illustration w-100"
                    alt="Control Hub"
                  />
                ) : (
                  <StaticImage
                    src="../assets/images/pages/api-builder/Illustration-main--mobile.png"
                    className="first-section__image illustration w-100"
                    alt="Control Hub"
                  />
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="c-section--primary">
          <div className="container">
            <div className="row">
              <h3 className="header-600 mb-4">Manage Integration Clusters</h3>
              <div className="col-md-5">
                <ul className="list-disc body-300">
                  <li>Manage and observe hybrid, multi-cluster deployments</li>
                  <li>Easily scale deployments based on demand and usage</li>
                  <li>Get powerful real-time insights as logs, configurations or traits</li>
                </ul>
              </div>
              <div className="col-md-6 offset-md-1 mt-4 mt-md-0">
                <p className="body-300">
                  Leverage the power of multi-cloud container based architectures and easily deploy,
                  observe and release services wherever needed. Choose between any combination of
                  managed cloud services, on-premises deploymeny or use our Sandbox to try something
                  out.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="c-section--white">
          <div className="container">
            <SectionFeature
              sectionClassName="pb-80"
              contentLabelText="Operate"
              contentTitleText="Deploy containers at scale"
              contentBodyTags={
                <>
                  <p className="body-300 gray-400 mb-4">
                    Manage your containerized Integrations across all clusters in one place.
                    Deep-dive into the actual state, deploy, undeploy, scale, manage settings and
                    monitor logs, all in real-time.
                  </p>
                </>
              }
              contentImage={{
                tag: !isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/control-hub/Illustration-operate.png"
                    alt="Deploy containers at scale"
                  />
                ) : (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/control-hub/Illustration-operate--mobile.png"
                    alt="Deploy containers at scale"
                  />
                ),
              }}
            />

            <SectionFeature
              sectionClassName="py-80"
              contentLabelText="Monitor"
              contentTitleText="State of the platform at a glance"
              contentBodyTags={
                <>
                  <p className="body-300 gray-400 mb-4">
                    Control Hub constantly monitors the status of the connected clusters and all
                    containerized Integrations for you. Get ahead of any issues before the business
                    gets impacted.
                  </p>
                </>
              }
              contentImage={{
                tag: !isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/control-hub/Illustration-monitor.png"
                    alt="State of the platform at a glance"
                  />
                ) : (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/control-hub/Illustration-monitor--mobile.png"
                    alt="State of the platform at a glance"
                  />
                ),
              }}
            />

            <SectionFeature
              sectionClassName="py-80"
              contentLabelText="Scale"
              contentTitleText="Automated CI/CD for safer roll-outs"
              contentBodyTags={
                <>
                  <p className="body-300 gray-400 mb-4">
                    Whether manual or fully automated release pipelines using GitOps-style
                    processes. Our platform easily plugs into your CI/CD stack and helps you improve
                    quality and stability at every stage.
                  </p>
                </>
              }
              contentImage={{
                tag: !isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/control-hub/Illustration-scale.png"
                    alt="Automated CI/CD for safer roll-outs"
                  />
                ) : (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/control-hub/Illustration-scale--mobile.png"
                    alt="Automated CI/CD for safer roll-outs"
                  />
                ),
              }}
            />

            <SectionFeature
              sectionClassName="pt-80"
              contentLabelText="3rd Party"
              contentTitleText="Pluggable Monitoring"
              contentBodyTags={
                <>
                  <p className="body-300 gray-400 mb-4">
                    We believe in best-of-breed and encourage our users to use the best technologies
                    at every angle of their stack. We natively fit into stacks like Prometheus for
                    metrics, Elastic for logs, or OpenTelemetry for observability.
                  </p>
                  <div
                    className={classNames('d-flex align-items-center', {
                      'flex-column': isMobile,
                    })}
                  >
                    <img src={logoElastic} alt="Elastic" />
                    <img className="mx-md-4" src={logoPrometheus} alt="Prometheus" />
                    <img src={logoOpenTelemetry} alt="OpenTelemetry" />
                  </div>
                </>
              }
              contentImage={{
                tag: !isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/control-hub/Illustration-3rd-party.png"
                    alt="Pluggable Monitoring"
                  />
                ) : (
                  <StaticImage
                    className="illustration mt-4"
                    src="../assets/images/pages/control-hub/Illustration-3rd-party--mobile.png"
                    alt="Pluggable Monitoring"
                  />
                ),
              }}
            />
          </div>
        </section>

        <section className="c-section--gray py-80">
          <div className="container">
            <h3 className="header-600 text-center mb-0">
              Building open-source Integrations and APIs has never been easier
            </h3>
          </div>
        </section>

        <section className="py-80" style={{ boxShadow: 'inset 0px -1px 0px #E8E9EA' }}>
          <div className="container">
            <div className="row mb-80">
              <div className="col-lg-11 mx-auto">
                {!isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/control-hub/Illustration-vb.png"
                    alt="Build.Run.Inspect"
                  />
                ) : (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/control-hub/Illustration-vb--mobile.png"
                    alt="Build.Run.Inspect"
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 pe-lg-5">
                <h4 className="header-500 mb-4">Deploy</h4>
                <ul className="list-check body-300 gray-400">
                  <li>Deploy and undeploy</li>
                  <li>Manage stages and clusters</li>
                  <li>Setup auto-scaling and replicas</li>
                </ul>
              </div>
              <div className="col-md-4 pe-lg-5 mt-4 mt-md-0">
                <h4 className="header-500 mb-4">Observe</h4>
                <ul className="list-check body-300 gray-400">
                  <li>List status of all integrations</li>
                  <li>Inspect logs in real-time</li>
                  <li>Integration details and summary</li>
                </ul>
              </div>
              <div className="col-md-4 pe-lg-5 mt-4 mt-md-0">
                <h4 className="header-500 mb-4">Release</h4>
                <ul className="list-check body-300 gray-400">
                  <li>Promote integrations between stages</li>
                  <li>Inspect exchange data on-the-fly</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="py-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="row">
                  <div
                    className={classNames('col-md-6 col-lg-5 d-flex align-items-center', {
                      'flex-wrap': isMobile,
                      'justify-content-center': isMobile,
                    })}
                  >
                    {isMobile && (
                      <img
                        className="mb-4"
                        src={iconLowCode}
                        alt="Containerized Integration DevOps without the headache"
                        height={48}
                        width={48}
                      />
                    )}
                    <h3
                      className={classNames('header-600', {
                        'text-center': isMobile,
                      })}
                    >
                      Containerized API & Integration DevOps without the headache
                    </h3>
                  </div>
                  {!isMobile && (
                    <div className="col-md-6 offset-lg-1">
                      <StaticImage
                        className="illustration"
                        src="../assets/images/pages/control-hub/Illustration-containerized-devops.png"
                        alt="Containerized Integration DevOps without the headache"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <SectionEarlyAccess />
      </div>
    </Layout>
  );
};

export default ControlHubPage;
