import React from 'react';

import '../../assets/styles/components/section-feature.scss';

const SectionFeature = ({
  sectionClassName,
  contentLabelText,
  contentTitleText,
  contentBodyTags,
  contentImage,
}) => (
    <section className={`c-section-feature ${sectionClassName}`}>
      <div className="row">
        <div className="c-section-feature__content col-md-5">
          <div>
            {contentLabelText && (
              <span className="c-label c-label--primary header-100 mb-3">{contentLabelText}</span>
            )}
            {contentTitleText && <h3 className="header-600 mb-4">{contentTitleText}</h3>}
            {contentBodyTags}
          </div>
        </div>
        <div className="c-section-feature__image col-md-7">{contentImage?.tag}</div>
      </div>
    </section>
  );

export default SectionFeature;
